<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div class="sidebar-shop" :class="{ show: mqShallShowLeftSidebar }">
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">
              <!-- {{ $t("company.Companies_Filters") }} -->
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <div class="divFilters">
          <b-row>
            <b-col cols="12" class="mx-1">
              <h6 class="filter-heading d-none d-lg-block">
                {{ $t("company.Companies_Filters") }}
              </h6>
              <b-button
                type="reset"
                variant="outline-secondary"
                @click="reset"
                class="mb-2 resetBtn"
              >
                {{ $t("company.Reset") }}
              </b-button>
            </b-col>
          </b-row>
          <app-collapse accordion>
            <app-collapse-item
              :title="$t('company.EBITDA_Margin')"
              class="ebitdaBlock"
            >
              <!-- EBITDA Slider -->
              <div class="price-slider ebitdaBlock">
                <vue-slider
                  v-model="filters.EBITDA"
                  :min="minEbitda"
                  :max="maxEbitda"
                  :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <label>{{ calcEBITDA }}</label>
              </div>
            </app-collapse-item>
            <app-collapse-item :title="$t('company.Transaction_Types')">
              <!-- Categories / Transaction Types Slider -->
              <div class="product-categories">
                <b-form-radio-group
                  v-model="filters.categories"
                  class="categories-radio-group"
                  stacked
                  :options="categories"
                />
              </div>
            </app-collapse-item>
            <app-collapse-item :title="$t('company.Legal_Entity_Type')">
              <!-- legal entity type Slider -->
              <div class="product-categories">
                <b-form-radio-group
                  v-model="filters.entityType"
                  class="categories-radio-group"
                  stacked
                  :options="filterOptions.entityType"
                />
              </div>
            </app-collapse-item>
            <app-collapse-item :title="$t('company.Monthly_Sales')">
              <!-- EBITDA Slider -->
              <div class="price-slider">
                <vue-slider
                  v-model="filters.MonthlySales"
                  :min="10000"
                  :max="1000000000"
                  :hide-label="true"
                  :lazy="true"
                  :interval="calcInterval"
                  :silent="true"
                  :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <label>{{
                  $store.state.locale.locale === "ar"
                    ? arConverMonthlyAvgToAmount
                    : converMonthlyAvgToAmount
                }}</label>
              </div>
            </app-collapse-item>
            <app-collapse-item :title="$t('company.Establish_Year')">
              <!-- EBITDA Slider -->
              <div class="price-slider">
                <vue-slider
                  v-model="filters.Established"
                  :min="1960"
                  :max="new Date().getFullYear()"
                  :interval="1"
                  :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
              </div>
            </app-collapse-item>
            <app-collapse-item :title="$t('company.Industry')">
              <!-- industry -->
              <div id="app">
                <treeselect
                  v-model="filters.industry"
                  :multiple="true"
                  :label="$t('company.select')"
                  :options="industries"
                  :normalizer="
                    $store.state.locale.locale == 'ar'
                      ? normalizerArabic
                      : normalizer
                  "
                />
              </div>
            </app-collapse-item>
            <app-collapse-item :title="$t('company.Location')">
              <!-- Location -->
              <div id="app">
                <treeselect
                  v-model="filters.location"
                  :multiple="true"
                  label="Select location ."
                  :options="countries"
                />
              </div>
            </app-collapse-item>
          </app-collapse>

          <!-- Multi Range -->
          <!-- <div class="multi-range-price">
            <h6 class="filter-title mt-0">Multi Range</h6>
            <b-form-radio-group
              v-model="filters.priceRangeDefined"
              class="price-range-defined-radio-group"
              stacked
              :options="filterOptions.priceRangeDefined"
            />
          </div> -->

          <!-- Price Slider -->
          <!-- <div class="price-slider">
            <h6 class="filter-title">Price Range</h6>
            <vue-slider
              v-model="filters.priceRange"
              :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
          </div> -->

          <!-- Categories -->
          <!-- <div class="product-categories">
            <h6 class="filter-title">Categories</h6>
            <b-form-radio-group
              v-model="filters.categories"
              class="categories-radio-group"
              stacked
              :options="filterOptions.categories"
            />
          </div> -->

          <!-- Brands -->
          <!-- <div class="brands">
            <h6 class="filter-title">Brands</h6>
            <b-form-radio-group
              v-model="filters.brands"
              class="brands-radio-group"
              stacked
              :options="filterOptions.brands"
            />
          </div> -->

          <!-- Ratings -->
          <div class="ratings ml-2 pb-2">
            <h6 class="filter-title">
              {{ $t("company.Ratings") }}
            </h6>
            <div
              v-for="rating in filterOptions.ratings"
              :key="rating.rating"
              class="ratings-list"
            >
              <b-link>
                <div class="d-flex">
                  <feather-icon
                    v-for="star in 5"
                    :key="star"
                    icon="StarIcon"
                    size="17"
                    :class="[
                      { 'fill-current': star <= rating.rating },
                      star <= rating.rating ? 'text-warning' : 'text-muted',
                    ]"
                    @click="log(rating.rating)"
                  />
                  <span class="ml-25">{{ $t("company.up") }}</span>
                </div>
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import millify from "millify";
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BFormRadioGroup,
  BLink,
} from "bootstrap-vue";
import VueSlider from "vue-slider-component";

export default {
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    BLink,
    BCard,
    BButton,
    AppCollapse,
    AppCollapseItem,
    Treeselect,
    // 3rd Party
    VueSlider,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      minEbitda: 0,
      maxEbitda: 100,
    };
  },
  computed: {
    calcInterval() {
      // `this` points to the component instance
      return (1000000000 - 10000) / 10000;
    },
    calcEBITDA() {
      // `this` points to the component instance
      return `${this.$t("company.between")}: ${this.filters.EBITDA[0]}% - ${
        this.filters.EBITDA[1]
      }%`;
    },
    converMonthlyAvgToAmount() {
      // `this` points to the component instance
      return `${millify(this.filters.MonthlySales[0], {
        precision: 3,
        decimalSeparator: ",",
        space: true,
      })} - ${millify(this.filters.MonthlySales[1], {
        precision: 3,
        decimalSeparator: ",",
        space: true,
      })}`;
    },
    arConverMonthlyAvgToAmount() {
      // `this` points to the component instance
      return `${millify(this.filters.MonthlySales[0], {
        precision: 3,
        units: ["مئة", "الف", "مليون", "مليار", "بليون"],
        decimalSeparator: ",",
        space: true,
      })} - ${millify(this.filters.MonthlySales[1], {
        precision: 3,
        units: ["مئة", "الف", "مليون", "مليار", "بليون"],
        decimalSeparator: ",",
        space: true,
      })}`;
    },
    estInterval() {
      // `this` points to the component instance
      return (this.filters.Established[1] - this.filters.Established[0]) / 1;
    },
    ...mapGetters({
      categories: "profile/getCompanyCategories",
      industries: "profile/getAllIndustries",
      countries: "country/getFilterCountries",
    }),
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCountries();
  },
  methods: {
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    reset() {
      this.filters.EBITDA = [0, 100];
      this.filters.MonthlySales = [10, 1000000000];
      this.filters.Established = [1960, new Date().getFullYear()];
      this.filters.industry = [];
      this.filters.location = [];
      this.filters.q = "";
      this.filters.categories = [];
      this.filters.entityType = [];
      this.filters.ratings = null;
    },
    log(id) {
      this.filters.ratings = id;
    },
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      fetchCurrencies: "currency/retreiveCurrencies",
      fetchCountries: "country/retreiveCountries",
    }),
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-slider.scss";
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
<style>
@media (max-width: 991.98px) {
  [dir] .ecommerce-application .sidebar-left .sidebar .sidebar-shop.show {
    margin-top: 65px;
  }
}
</style>
<style scoped>
.divFilters {
  background-color: #fff;
  padding-top: 20px;
  border: 1px solid #ebe9f1;
  margin-top: 1rem;
}
@media (max-width: 600px) {
  .divFilters {
    margin-top: 0rem;
  }
}
div.card.ebitdaBlock {
  border-top: 1px solid #ebe9f1;
}
.resetBtn {
  width: 88%;
}
.divFilters .card {
  border: 0;
  border-bottom: 1px solid #ebe9f1;
}

.divFilters h6 {
  font-weight: 500;
  font-size: 1.1rem;
}
</style>
