import { ref } from "@vue/composition-api";
import store from "@/store";

export const useShopFiltersSortingAndPagination = () => {
  const filters = ref({
    q: "",
    priceRangeDefined: "all",
    EBITDA: [0, 100],
    MonthlySales: [10, 1000000000],
    categories: [],
    entityType: [],
    Established: [1960, new Date().getFullYear()],
    industry: null,
    ratings: null,
    location: null,
    page: 1,
    perPage: 9,
  });

  const filterOptions = {
    // priceRangeDefined: [
    //   { text: "All", value: "all" },
    //   { text: "<= $10", value: "<=10" },
    //   { text: "$10 - $100", value: "10-100" },
    //   { text: "$100 - $500", value: "100-500" },
    //   { text: ">= $500", value: ">=500" },
    // ],
    categories: [
      "Full Sale",
      "Investment",
      "Loan for business",
      "Selling or Leasing",
    ],
    entityType: [
      {
        value: "1",
        text:
          localStorage.getItem("language") === "en"
            ? "Sole Proprietorship /Sole Trader"
            : "مؤسسة فردية / تاجر وحيد",
      },
      {
        value: "2",
        text:
          localStorage.getItem("language") === "en"
            ? "General Partnership"
            : "شراكة عامة",
      },
      {
        value: "3",
        text:
          localStorage.getItem("language") === "en"
            ? "Limited Liability Partnership (LLP)"
            : "شراكة ذات مسؤولية محدودة(ش.ذ.م.م)",
      },
      {
        value: "4",
        text:
          localStorage.getItem("language") === "en"
            ? "Limited Liability Company (LLC)"
            : "شركة ذات مسؤولية محدودة(ش.ذ.م.م)",
      },
      {
        value: "5",
        text:
          localStorage.getItem("language") === "en"
            ? "Private Limited Company"
            : "شركة خاصة محدودة ",
      },
      {
        value: "6",
        text:
          localStorage.getItem("language") === "en"
            ? "Public Limited Company"
            : "شركه عالميه محدوده",
      },
      {
        value: "7",
        text:
          localStorage.getItem("language") === "en"
            ? "S Corporation"
            : "S Corporation",
      },
      {
        value: "8",
        text:
          localStorage.getItem("language") === "en"
            ? "C Corporation"
            : "C Corporation",
      },
      {
        value: "9",
        text: localStorage.getItem("language") === "en" ? "Other" : "غير ذلك",
      },
    ],

    ratings: [{ rating: 4 }, { rating: 3 }, { rating: 2 }, { rating: 1 }],
  };

  // Sorting
  const sortBy = ref({ text: "Featured", value: "featured" });
  const sortByOptions = [
    { text: "Featured", value: "featured" },
    { text: "Lowest", value: "price-asc" },
    { text: "Highest", value: "price-desc" },
  ];

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  };
};

export const useShopUi = () => {
  const itemView = "grid-view";
  const itemViewOptions = [
    { icon: "GridIcon", value: "grid-view" },
    { icon: "ListIcon", value: "list-view" },
  ];

  // Pagination count <= required by pagination component
  const totalProducts = ref(null);

  return {
    itemView,
    itemViewOptions,
    totalProducts,
  };
};

export const useShopRemoteData = () => {
  const products = ref([]);
  const fetchProducts = (data) =>
    store.dispatch("profile/retreiveCompanies", data);

  return {
    products,
    fetchProducts,
  };
};
