<template>
  <b-link class="cardCompany" :href="`/profile/${company.username}`">
    <div class="logo">
      <img :src="companyImage(company.companyImg)" :alt="company.username" />
      <div class="ratings">
        <div class="item-rating">
          <ul class="unstyled-list list-inline">
            <li
              v-for="star in 5"
              :key="star"
              class="ratings-list-item"
              :class="{ 'ml-25': star - 1 }"
            >
              <feather-icon
                icon="StarIcon"
                size="16"
                :class="[
                  { 'fill-current': star <= company.ratingNumber },
                  star <= company.ratingNumber ? 'text-warning' : 'text-muted',
                ]"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="info">
      <h3>{{ company.name }}</h3>
      <div class="type">
        {{ retrunCompanyTypeName(company.companyType) }}
      </div>
      <div class="location">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokewidth="{1.5}"
          stroke="currentColor"
          class="icon h-[15px] h-[15px]"
        >
          <path
            strokelinecap="round"
            strokelinejoin="round"
            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path
            strokelinecap="round"
            strokelinejoin="round"
            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
          />
        </svg>
        <div>
          <span v-if="company.country" class="item-located-text"
            >{{ company.country
            }}<span v-if="company.state">
              {{ ", " + company.state }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="industries">
      <span v-for="(ind, index) in company.industries" :key="index">{{
        $store.state.locale.locale == "ar" ? "#" + ind.arabicName + " " : "#" + ind.name + " "
      }}</span>
    </div>

    <div class="meta">
      <ul>
        <li>
          <div class="label">
            {{ $t("company.Monthly_Sales") }}
          </div>
          <div class="value">
            {{
              $store.state.locale.locale == "en"
                ? calcMonthlySales(currencyExchange(company.avgMonthlySales, company.currency))
                : arCalcMonthlySales(currencyExchange(company.avgMonthlySales, company.currency))
            }}
            <span>{{ $store.state.myCurrency.myCurrency.symbol }}</span>
          </div>
        </li>
        <li>
          <div class="label">
            {{ $t("company.EBITDA_Margin") }}
          </div>
          <div class="value">{{ company.ebitda }}%</div>
        </li>
        <li>
          <div class="label">
            {{ company.companyCategory }}
          </div>
          <div class="value">
            <span v-if="company.price"
              >{{
                $store.state.locale.locale === "en"
                  ? calcMonthlySales(currencyExchange(company.price, company.currency))
                  : arCalcMonthlySales(currencyExchange(company.price, company.currency))
              }}
            </span>
            <span v-if="company.currency && company.price != 1">{{
              $store.state.myCurrency.myCurrency.symbol
            }}</span>
          </div>
        </li>
      </ul>
    </div>

    <button class="btn btn-sm btn-primary">Profile</button>
  </b-link>
</template>
<script>
import { BLink } from "bootstrap-vue";
import millify from "millify";

export default {
  components: {
    BLink,
  },
  props: {
    company: {
      type: Object,
      default: {},
    },
  },
  methods: {
    companyImage(data) {
      let imgCompany = "";

      if (data != null) {
        imgCompany = data;
      } else {
        imgCompany = require("@/assets/images/index/company.png");
        // imgadvisor = false;
      }

      return imgCompany;
    },
    retrunCompanyTypeName(id) {
      let companyType = "";
      switch (id) {
        case 1:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Sole Proprietorship"
              : "مؤسسة فردية / تاجر وحيد";
          break;
        case 2:
          companyType =
            localStorage.getItem("language") === "en" ? "General Partnership" : "شراكة عامة";
          break;
        case 3:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Limited Liability Partnership (LLP)"
              : "شراكة ذات مسؤولية محدودة(ش.ذ.م.م)";
          break;
        case 4:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Limited Liability Company (LLC)"
              : "شركة ذات مسؤولية محدودة(ش.ذ.م.م)";
          break;
        case 5:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Private Limited Company"
              : "شركة خاصة محدودة ";
          break;
        case 6:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Public Limited Company"
              : "شركه عالميه محدوده";
          break;
        case 7:
          companyType =
            localStorage.getItem("language") === "en" ? "S Corporation" : "S Corporation";
          break;
        case 8:
          companyType =
            localStorage.getItem("language") === "en" ? "C Corporation" : "C Corporation";
          break;
        case 9:
          companyType = localStorage.getItem("language") === "en" ? "Other" : "غير ذلك";
          break;
        default:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Sole Proprietorship"
              : "مؤسسة فردية / تاجر وحيد";
      }
      return companyType;
    },
    calcMonthlySales(number) {
      return `${millify(number, {
        precision: 1,
        decimalSeparator: ".",
        space: true,
      })} `;
    },
    arCalcMonthlySales(number) {
      return `${millify(number, {
        precision: 1,
        units: ["", "الف", "مليون", "مليار", "بليون"],
        decimalSeparator: ".",
        space: true,
      })} `;
    },
  },
};
</script>
<style scoped>
.cardCompany {
  display: block;
  background-color: #fff;
  border-radius: 0.375rem;
  padding: 0.75rem;
  height: 100%;
  border: 1px solid rgb(229, 231, 235);
  -webkit-transition: 0.3s linear all;
}
.cardCompany:hover {
}
.cardCompany .logo {
  display: flex;
  height: 70px;
  align-content: center;
  padding: 0.75rem 0;
  padding-bottom: 0.5rem;
}
.cardCompany .logo img {
  margin-bottom: 0.5rem;
  display: block;
  height: 100%;
  max-width: 153px;
}
.cardCompany .logo .ratings {
  margin-left: auto;
  display: flex;
  align-content: center;
  justify-content: center;
  color: rgb(251 146 60 / 1);
}
.cardCompany .info {
  /* margin: 0.5rem; */
  border-bottom: 1px solid rgb(243 244 246 / 1);
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.cardCompany .info h3 {
  margin-bottom: 0px;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: rgb(55 65 81 / 1);

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cardCompany .info .type {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgb(59 59 59);

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cardCompany .info .location {
  display: flex;
  padding-top: 0.25rem;
  font-size: 0.9rem;
  line-height: 1rem;
  color: rgb(0 93 252);
  align-items: center;
}
.cardCompany .info .location svg {
  margin-right: 1px;
  width: 20px;
  height: 20px;
  color: #0b2a7e;
}
.cardCompany .industries {
  display: flex;
  /* padding-left: 0.5rem; */
  padding-right: 0.5rem;
  overflow: hidden;
  position: relative;
}
.cardCompany .industries:after {
  content: " ";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 50px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}
.cardCompany .industries span {
  margin-right: 0.25rem;
  display: block;
  cursor: pointer;
  background-color: rgb(243 244 246/1);
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  /* color: rgb(107 114 128/1); */
  white-space: nowrap;
  color: #2f2d7e;
  font-size: 13px;
}
.cardCompany .meta {
  margin: 0.5rem 0;
}
.cardCompany .meta ul {
  display: flex;
  padding-left: 0px;
  flex-direction: column;
  list-style: none;
}
.cardCompany .meta li {
  display: flex;
  border-bottom: 1px solid rgb(243 244 246 / 1);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.6rem;
  color: rgb(156 163 175 / 1);
  justify-content: space-between;
}
.cardCompany .meta li:last-child {
  border-bottom: none;
}
.cardCompany .meta li .label {
  width: 150px;
  color: rgb(107 114 128 / 1);
  font-size: 1rem;
}
.cardCompany .meta li .value {
  font-size: 0.9rem;
  font-weight: 500;
  color: #3e64b2;
}

.cardCompany .highlights {
  min-height: 50px;
}
.cardCompany .highlights span {
  color: #2a2a2a;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
