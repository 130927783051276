<template>
  <div>
    <Header class="header-companylist" />

    <div class="company-list-head">
      <div class="company-list-body">
        <div style="height: inherit">
          <div v-if="!loading">
            <skeleton-list-vue
              :loading="!loading"
              :item-number="6"
              :number-in-row="4"
              :height="'1000'"
            />
          </div>
          <div v-if="loading">
            <!-- ECommerce Header -->

            <section id="ecommerce-header">
              <div class="row">
                <div class="col-sm-12">
                  <div class="ecommerce-header-items">
                    <div class="result-toggler">
                      <feather-icon
                        icon="MenuIcon"
                        class="d-block d-lg-none"
                        size="21"
                        @click="mqShallShowLeftSidebar = true"
                      />
                      <!-- <div class="search-results">
                      {{ totalProducts }} {{ $t("company.results_found") }}
                    </div> -->
                      <br />
                    </div>
                    <div class="view-options d-flex">
                      <!-- Sort Button -->
                      <!-- <b-dropdown
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :text="sortBy.text"
                      right
                      variant="outline-primary"
                    >
                      <b-dropdown-item
                        v-for="sortOption in sortByOptions"
                        :key="sortOption.value"
                        @click="sortBy = sortOption"
                      >
                        {{ sortOption.text }}
                      </b-dropdown-item>
                    </b-dropdown> -->

                      <!-- Item View Radio Button Group  -->
                      <!-- <b-form-radio-group
                    v-model="itemView"
                    class="ml-1 list item-view-radio-group"
                    buttons
                    size="sm"
                    button-variant="outline-primary"
                  >
                    <b-form-radio
                      v-for="option in itemViewOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      <feather-icon :icon="option.icon" size="18" />
                    </b-form-radio>
                  </b-form-radio-group> -->
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <!-- Overlay -->
            <div class="body-content-overlay" />

            <!-- Searchbar -->
            <div class="ecommerce-searchbar mt-1">
              <b-row>
                <b-col cols="12">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      v-model="filterParams.q"
                      :placeholder="$t('company.Search_in_companies')"
                      class="search-product"
                    />
                    <b-input-group-append is-text>
                      <feather-icon icon="SearchIcon" class="text-muted" />
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </div>

            <!-- Prodcuts -->
            <section :class="itemView">
              <b-card
                v-for="product in companies"
                :key="product.id"
                class="ecommerce-card"
                no-body
              >
                <company-card :company="product" />
              </b-card>
            </section>
            <!-- Pagination -->
            <section>
              <b-row>
                <b-col cols="12">
                  <b-pagination
                    v-model="filterParams.page"
                    :total-rows="compayCount"
                    :per-page="filterParams.perPage"
                    first-number
                    align="center"
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </section>
          </div>

          <!-- Sidebar -->
          <portal v-if="!loading" to="content-renderer-sidebar-detached-left">
            <leftSideSkeletonVue />
          </portal>

          <portal v-if="loading" to="content-renderer-sidebar-detached-left">
            <shop-left-filter-sidebar
              :filters="filters"
              :filter-options="filterOptions"
              :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
            />
          </portal>
        </div>
      </div>
    </div>
    <Footer class="footer-companyList" />
  </div>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
import store from "@/store";
import millify from "millify";

import Header from "@/layouts/landpage/Header.vue";
import Footer from "@/layouts/landpage/Footer.vue";

import CompanyCard from "@/layouts/landpage/company/CompanyCardFilter.vue";
import CompanyCard2 from "@/views/nftdubai/components/CompanyCard.vue";
import SkeletonListVue from "@/views/nftdubai/components/SkeletonList.vue";
import leftSideSkeletonVue from "@/views/nftdubai/components/leftSideSkeleton.vue";

import {
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BSkeleton,
  BButton,
  BPagination,
  BSkeletonImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";

// eslint-disable-next-line import/no-cycle
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import { mapActions, mapGetters } from "vuex";
import ShopLeftFilterSidebar from "./ECommerceShopLeftFilterSidebar.vue";
// eslint-disable-next-line import/no-cycle
import {
  useShopFiltersSortingAndPagination,
  useShopUi,
  useShopRemoteData,
} from "./useECommerceShop";
// eslint-disable-next-line import/no-cycle
import { useEcommerceUi } from "./useEcommerce";

export default {
  directives: {
    Ripple,
  },
  components: {
    SkeletonListVue,
    Header,
    BSkeleton,
    BSkeletonImg,
    Footer,
    CompanyCard,
    leftSideSkeletonVue,
    // BSV
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BButton,
    BPagination,

    // SFC
    ShopLeftFilterSidebar,
  },
  data() {
    return {
      subname: "",
    };
  },

  created() {
    // $themeConfig.layout.menu.hidden = true;
    // console.log(store.state.appConfig.layout.menu.hidden);
    // console.log(store.state.appConfig.layout.footerType);
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "hidden" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "hidden" });
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters({
      companies: "profile/getAllCompanies",
      compayCount: "profile/getCompanyCount",
    }),
  },
  mounted() {
    this.substrData();
  },
  methods: {
    calcMonthlySales(number) {
      return `${millify(number, {
        precision: 1,
        decimalSeparator: ".",
        space: true,
      })} `;
    },
    arCalcMonthlySales(number) {
      return `${millify(number, {
        precision: 1,
        units: ["مئة", "الف", "مليون", "مليار", "بليون"],
        decimalSeparator: ".",
        space: true,
      })} `;
    },
    ...mapActions({
      fetchCompanies: "profile/retreiveCompanies",
    }),
    formatDate(value) {
      return moment(String(value)).format("LL");
    },
    substrData() {
      // this.subname = name.substring(1, 4);
      // produc-name
      const elements = document.getElementsByClassName("produc-name");
      // console.log(elements.innerText);

      // alert(text1);
    },
  },
  setup() {
    const filterParams = ref({
      page: 1,
      perPage: 9,
      q: "",
    });
    const loading = ref(false);
    function retrunCompanyTypeName(id) {
      let companyType = "";
      switch (id) {
        case 1:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Sole Proprietorship /Sole Trader"
              : "مؤسسة فردية / تاجر وحيد";
          break;
        case 2:
          companyType =
            localStorage.getItem("language") === "en"
              ? "General Partnership"
              : "شراكة عامة";
          break;
        case 3:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Limited Liability Partnership (LLP)"
              : "شراكة ذات مسؤولية محدودة(ش.ذ.م.م)";
          break;
        case 4:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Limited Liability Company (LLC)"
              : "شركة ذات مسؤولية محدودة(ش.ذ.م.م)";
          break;
        case 5:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Private Limited Company"
              : "شركة خاصة محدودة ";
          break;
        case 6:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Public Limited Company"
              : "شركه عالميه محدوده";
          break;
        case 7:
          companyType =
            localStorage.getItem("language") === "en"
              ? "S Corporation"
              : "S Corporation";
          break;
        case 8:
          companyType =
            localStorage.getItem("language") === "en"
              ? "C Corporation"
              : "C Corporation";
          break;
        case 9:
          companyType =
            localStorage.getItem("language") === "en" ? "Other" : "غير ذلك";
          break;
        default:
          companyType = "Sole Proprietorship/Sole Trader";
      }
      return companyType;
    }
    const { filters, filterOptions, sortBy, sortByOptions } =
      useShopFiltersSortingAndPagination();

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi();

    const { itemView, itemViewOptions, totalProducts } = useShopUi();

    const { products, fetchProducts } = useShopRemoteData();

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      const formData = new FormData();
      formData.append("perPage", filterParams.value.perPage);
      formData.append("page", filterParams.value.page);
      if (filterParams.value.q) {
        formData.append("q", filterParams.value.q);
      }
      formData.append("ebitda", JSON.stringify(filters.value.EBITDA));
      if (filters.value.categories && filters.value.categories.length > 0) {
        formData.append("transactionType", filters.value.categories);
      }
      if (filters.value.entityType && filters.value.entityType.length > 0) {
        formData.append("companyType", filters.value.entityType);
      }
      formData.append(
        "monthlySales",
        JSON.stringify(filters.value.MonthlySales)
      );
      formData.append(
        "establishYear",
        JSON.stringify(filters.value.Established)
      );
      if (filters.value.industry && filters.value.industry.length > 0) {
        formData.append("industries", JSON.stringify(filters.value.industry));
      }
      if (filters.value.location && filters.value.location.length > 0) {
        formData.append("location", JSON.stringify(filters.value.location));
      }
      if (filters.value.ratings) {
        formData.append("rating", JSON.stringify([filters.value.ratings, 5]));
      }

      fetchProducts(formData)
        .then(() => {
          loading.value = true;
          window.scrollTo(0, 0);
        })
        .catch(() => {});
    };

    fetchShopProducts();

    watch(
      [filters, sortBy, filterParams],
      () => {
        fetchShopProducts();
      },
      {
        deep: true,
      }
    );

    return {
      // useShopFiltersSortingAndPagination
      filters,
      retrunCompanyTypeName,
      filterOptions,
      sortBy,
      sortByOptions,
      loading,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,
      filterParams,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
<style scoped>
@media (min-width: 992px) {
  .footer-companyList {
    margin-left: -316px;
    margin-right: -30px;
    margin-bottom: -22px;
  }
}

.item-date-located {
  display: flex;
  justify-content: space-between;
}
.item-located-text {
  color: #000;
}
.rate-sales {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  margin-bottom: 2px;
}
.item-EBITDA {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 2px;
}
.item-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.item-social .fa-brands {
  margin-right: 10px;
}
.item-wrapper {
  margin-bottom: 5px;
}
.item-desc-img {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  min-height: 120px;
}
.item-desc-img p {
  font-size: 13px;
}
.fa-location-dot {
  color: #516ccf;
}

.rate-sales .rate-sales-text,
.item-EBITDA .item-EBITDA-text {
  color: #0b2c8d;
  font-weight: 500;
}
.rate-sales .rate-sales-value,
.item-EBITDA .item-EBITDA-value {
  color: #000;
  font-weight: 500;
}
.rate-sales p,
.item-EBITDA p {
  padding: 5px 5px 0 5px;
}
.sale-type {
  margin-left: 40px;
}
.sale-type .sale-type-text {
  font-weight: 500;
  text-align: center;
  color: #6e6b7b;
}
.sale-type .sale-type-value {
  color: #000;
  font-weight: 500;
  text-align: center;
  margin-top: -10px;
}
.item-wrapper {
  margin-top: 10px;
}

.product-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.description {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6e6b7b;
}
.industry-value {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6e6b7b;
}
.item-legalEntity {
  color: #6e6b7b;
}
.company-list-body {
  min-height: 900px;
}
</style>
<style scoped>
.breadcrumbs-top {
  margin-top: 65px;
}
.company-list-head {
  margin-top: 22px;
}
@media (max-width: 600px) {
  [dir] .company-list-head {
    margin-top: 55px;
  }
}
</style>
<style>
[dir] .content-wrapper {
  padding-bottom: 0px;
  margin-top: 15px;
}
[dir] .ecommerce-application .ecommerce-card:hover {
  transform: translateY(0px);
  box-shadow: 0 0px 0px 0 rgb(34 41 47 / 8%);
}
[dir] .ecommerce-application .ecommerce-searchbar .input-group {
  border: 1px solid rgb(238 238 238);
  box-shadow: 2px 1px 2px rgb(0 0 0 / 6%);
}

footer.footer.footer-light.footer-static {
  display: none;
}
</style>
